import './DashboardDetails.scss'
import React from 'react';

const MySvgComponent = () => (
  <svg width="200" height="300" viewBox="0 0 200 300" >
    <style>
      {`
        .phone {
          fill: #4CAF50;
        }
        .money {
          fill: #FFC107;
          animation: moveMoney 2s infinite;
        }
        .likes {
          fill: #2196F3;
          animation: moveLikes 3s infinite;
        }
        .smile {
          fill: #FFEB3B;
          animation: moveSmile 4s infinite;
        }

        @keyframes moveMoney {
          0% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
          100% { transform: translateY(0); }
        }
        @keyframes moveLikes {
          0% { transform: translateX(0); }
          50% { transform: translateX(10px); }
          100% { transform: translateX(0); }
        }
        @keyframes moveSmile {
          0% { transform: translateY(0); }
          50% { transform: translateY(10px); }
          100% { transform: translateY(0); }
        }
      `}
    </style>
    
    {/* Phone */}
    <rect x="80" y="80" width="40" height="80" className="phone"/>
    
    {/* Money */}
    <circle cx="100" cy="70" r="10" className="money"/>
    
    {/* Likes */}
    <circle cx="140" cy="100" r="10" className="likes"/>
    
    {/* Smile */}
    <circle cx="60" cy="100" r="10" className="smile"/>
  </svg>
);

export default MySvgComponent;
