import "./AddInvantory.scss"
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useRecoilState } from "recoil";
import { $ServerUrl } from "../../../stoore";
export default function AddInvantory(props) {
    const [products, setProducts] = useState([]);
    const [warehouse, setWarehouse] = useState([]);
    const [location, setLocation] = useState([]);
    // const [inventors, setInventory] = useState();
    const [productName, setProductName] = useState();
    const [warehouseName, setWarehouseName] = useState();
    const [locationName, setLocationName] = useState();
    const [serialNumber, setSerialNumber] = useState();
    const [Qty, setQty] = useState();
    const [serverUrl] = useRecoilState($ServerUrl);
    const [popupAdd, setPopupAdd] = useState(true);
    const fetchProducts = async () => {
        try {
          const response = await axios.get(`${serverUrl}/products`);
          setProducts(response.data.response.data);
          // console.log(response.data.response.data);
        } catch (error) {
          console.log(error);
        }
      };
    
    
      const fetchWarehouse = async () => {
        try {
          const response = await axios.get(`${serverUrl}/warehouses`);
          setWarehouse(response.data.response.data);
          // console.log(response.data.response.data);
        } catch (error) {
          console.log(error);
        }
      };
    
    
    
      const fetchlLocations = async () => {
        // console.log(error);
        try {
          const response = await axios.get(`${serverUrl}/inventory-locations`);
          setLocation(response.data.response.data);
          // console.log(response.data.response.data);
        } catch (error) {
          console.log(error);
        }
      };
    
    
      const postData = async () => {
        try {
          const postData = await axios.post(
            `${serverUrl}/inventories`,
            {
              product_id: products.filter((s) => s.name === productName)[0].id,
              warehouse_id: warehouse.filter((s) => s.name === warehouseName)[0].id,
              quantity:Qty,
              serial_number:serialNumber,
              location_id: location.filter((s) => s.location_code === locationName)[0].id,
            }
          );
          // console.log("Success");
          // const purchaseId = postData.data.response.data.id;
          // navigate(`/purchase/${purchaseId}`);
        } catch (error) {
          console.log(error);
          // Swal.fire({
          //   title: "Error",
          //   text: "That thing is still around?",
          //   icon: "error",
          // });
        }
      };
      useEffect(() => {
        // fetchInventory();
        fetchProducts();
        fetchWarehouse();
        fetchlLocations();
        // console.log(location);
        
        
      }, []);
    
      // console.log(inventors);
      const submit = (e) => {
        e.preventDefault();
        postData();
        props.popup();
        // updateData(purchase.id);
      };
    return(
        <>
 <div className="container-form-edit-purchase">
        <form onSubmit={submit} className="form-data container">
      

         
          <label htmlFor="expected_date" className="label-input">
          Product
          </label>
           <select
          className="form-select input-form"
          aria-label="Default select example"
          onChange={(e) => {
            setProductName(e.target.value);
          }}
        >
          {products.map((item) => (
            <>
              <option>{item.name}</option>
            </>
          ))}
        </select>

       

          <label htmlFor="expected_date" className="label-input">
          Warehouse
          </label>
           <select
          className="form-select input-form"
          aria-label="Default select example"
          onChange={(e) => {
            setWarehouseName(e.target.value);
          }}
        >
          {warehouse.map((item) => (
            <>
              <option>{item.name}</option>
            </>
          ))}
        </select>



        <label htmlFor="expected_date" className="label-input">
        Location
          </label>
          <select
          className="form-select input-form"
          aria-label="Default select example"
          // value={status}
          onChange={(e) => {
            setLocationName(e.target.value);
          }}
        >
          {location.map((item) => (
            <>
              <option>{item.location_code}</option>
            </>
          ))}
        </select>






      


        <label htmlFor="" className="label-input">
       Serial Number
          </label>
          <input
            type="number"
            className="input-form"
            // value={Order}
            onChange={(e) => {
              setSerialNumber(e.target.value);
            }}
          />


        <label htmlFor="" className="label-input">
        quantity
          </label>
          <input
            type="number"
            className="input-form"
            // value={Order}
            onChange={(e) => {
              setQty(e.target.value);
            }}
          />

       




        
          <div className="container-btns">
            <button
              className="btn btn-danger my-style-btn-form"
              // onClick={popupEdit}
              onClick={props.popup}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary my-style-btn-form"
            >
              Update
            </button>
          </div>
        </form>
      </div>
        </>
    )
}