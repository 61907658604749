
import "./Products.scss";

export default function Products() {
 

  return (
   
  <svg width="100" height="100" viewBox="0 0 500 500">
    <path
      d="M379.957 159.593C386.507 160.265 391.274 166.119 390.602 172.67C389.931 179.22 384.077 183.986 377.526 183.315C370.975 182.644 366.209 176.789 366.88 170.239C367.551 163.688 373.406 158.922 379.957 159.593Z"
      fill="#80D672"
      className="breathe-vertical"
    />
  </svg>
   
  );
}



// import "./Inventory.scss";
// import { useRecoilState } from "recoil";
// import { $ServerUrl } from "../../stoore";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// // import AddInvantory from "../componentsInvantory/addInvantory/AddInvantory";
// // import EditInvantory from "../componentsInvantory/editInvantory/EditInvantory";

// export default function Inventory(props) {
//   const [serverUrl] = useRecoilState($ServerUrl);
//   const [products, setProducts] = useState([]);
//   // const [products, setProducts] = useState([]);
//   // const [warehouse, setWarehouse] = useState([]);
//   // const [location, setLocation] = useState([]);
//   // const [currentInventory, setCurrentInventory] = useState(null); // For tracking the current inventory being edited

//   const [popupAdd, setPopupAdd] = useState(false);
//   const [popupEdit, setPopupEdit] = useState(false);

//   const fetchProducts = async () => {
//     try {
//       const response = await axios.get(`${serverUrl}/products`);
//       setProducts(response.data.response.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // const EditFunction = async () => {
//   //   if (!currentInventory) return; // Ensure there's an inventory item to edit

//   //   try {
//   //     await axios.put(`${serverUrl}/inventories/${currentInventory.id}`, {
//   //       product_id: products.find((s) => s.name === currentInventory.product_name).id,
//   //       warehouse_id: warehouse.find((s) => s.name === currentInventory.warehouse_name).id,
//   //       quantity: currentInventory.quantity,
//   //       serial_number: currentInventory.serial_number,
//   //       location_id: location.find((s) => s.location_code === currentInventory.location_code).id,
//   //     });

//   //     Swal.fire({
//   //       title: "Success",
//   //       text: "Inventory updated successfully!",
//   //       icon: "success",
//   //     });

//   //     fetchInventory();
//   //     popupEditt(); // Close the edit popup
//   //   } catch (error) {
//   //     console.log(error);
//   //     Swal.fire({
//   //       title: "Error",
//   //       text: "Failed to update inventory.",
//   //       icon: "error",
//   //     });
//   //   }
//   // };

//   // const popupAddd = () => {
//   //   setPopupAdd(!popupAdd);
//   // };

//   // const popupEditt = (inventory = null) => {
//   //   setPopupEdit(!popupEdit);

//   //   if (inventory) {
//   //     setCurrentInventory({
//   //       id: inventory.id,
//   //       product_name: inventory.product_name,
//   //       warehouse_name: inventory.warehouse_name,
//   //       location_code: inventory.location_code,
//   //       serial_number: inventory.serial_number,
//   //       quantity: inventory.quantity,
//   //     });
//   //   } else {
//   //     setCurrentInventory(null);
//   //   }
//   // };

//   // const fetchProducts = async () => {
//   //   try {
//   //     const response = await axios.get(`${serverUrl}/products`);
//   //     setProducts(response.data.response.data);
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//   // const fetchWarehouse = async () => {
//   //   try {
//   //     const response = await axios.get(`${serverUrl}/warehouses`);
//   //     setWarehouse(response.data.response.data);
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//   // const fetchlLocations = async () => {
//   //   try {
//   //     const response = await axios.get(`${serverUrl}/inventory-locations`);
//   //     setLocation(response.data.response.data);
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//   // async function deletee(id) {
//   //   try {
//   //     await axios.delete(`${serverUrl}/inventories/${id}`);
//   //     Swal.fire({
//   //       title: "Success",
//   //       text: "Inventory deleted successfully!",
//   //       icon: "success",
//   //     });
//   //     fetchInventory();
//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   // }

//   useEffect(() => {
//     fetchInventory();
//     fetchProducts();
//     fetchWarehouse();
//     fetchlLocations();
//   }, []);

//   // const submit = (e) => {
//   //   e.preventDefault();
//   //   EditFunction();
//   // };

//   return (
//     <>
//       {/* <div className="container table-data">
//         <button className="btn btn-success my-style-btn" onClick={popupAddd}>
//           ADD Inventory
//         </button>

//         <table className="table table-striped table-dark table-style">
//           <thead>
//             <tr>
//               <th className="title-head-table" scope="col">Product Name</th>
//               <th className="title-head-table" scope="col">Warehouse Name</th>
//               <th className="title-head-table" scope="col">Location Code</th>
//               <th className="title-head-table" scope="col">Serial Number</th>
//               <th className="title-head-table" scope="col">Quantity</th>
//               <th className="title-head-table" scope="col">Created At</th>
//               <th className="title-head-table" scope="col">DELETE</th>
//               <th className="title-head-table" scope="col">EDIT</th>
//               <th className="title-head-table" scope="col">VIEW</th>
//             </tr>
//           </thead>
//           <tbody>
//             {inventors.map((inv, index) => (
//               <tr key={index}>
//                 <td className="data-table-purchase">{inv.product_name}</td>
//                 <td className="data-table-purchase">{inv.warehouse_name}</td>
//                 <td className="data-table-purchase">{inv.location_code}</td>
//                 <td className="data-table-purchase">{inv.serial_number}</td>
//                 <td className="data-table-purchase">{inv.quantity}</td>
//                 <td className="data-table-purchase">{inv.created_at.slice(0, 7)}</td>
//                 <td className="data-table-purchase">
//                   <button className="btn btn-danger" onClick={() => deletee(inv.id)}>Delete</button>
//                 </td>
//                 <td>
//                   <button className="btn btn-warning" onClick={() => popupEditt(inv)}>Edit</button>
//                 </td>
//                 <td>
//                   <button className="btn btn-primary">View</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div> */}

//       {/* {popupAdd && <AddInvantory popup={popupAddd} />} */}

//       {/* {popupEdit && currentInventory && (
//         <div className="container-form-edit-purchase">
//           <form onSubmit={submit} className="form-data container">
//             <label htmlFor="expected_date" className="label-input">
//               Product
//             </label>
//             <select
//               className="form-select input-form"
//               value={currentInventory.product_name}
//               onChange={(e) =>
//                 setCurrentInventory({ ...currentInventory, product_name: e.target.value })
//               }
//             >
//               {products.map((item) => (
//                 <option key={item.id} value={item.name}>
//                   {item.name}
//                 </option>
//               ))}
//             </select>

//             <label htmlFor="expected_date" className="label-input">
//               Warehouse
//             </label>
//             <select
//               className="form-select input-form"
//               value={currentInventory.warehouse_name}
//               onChange={(e) =>
//                 setCurrentInventory({ ...currentInventory, warehouse_name: e.target.value })
//               }
//             >
//               {warehouse.map((item) => (
//                 <option key={item.id} value={item.name}>
//                   {item.name}
//                 </option>
//               ))}
//             </select>

//             <label htmlFor="expected_date" className="label-input">
//               Location
//             </label>
//             <select
//               className="form-select input-form"
//               value={currentInventory.location_code}
//               onChange={(e) =>
//                 setCurrentInventory({ ...currentInventory, location_code: e.target.value })
//               }
//             >
//               {location.map((item) => (
//                 <option key={item.id} value={item.location_code}>
//                   {item.location_code}
//                 </option>
//               ))}
//             </select>

//             <label htmlFor="" className="label-input">
//               Serial Number
//             </label>
//             <input
//               type="text"
//               className="input-form"
//               value={currentInventory.serial_number}
//               onChange={(e) =>
//                 setCurrentInventory({ ...currentInventory, serial_number: e.target.value })
//               }
//             />

//             <label htmlFor="" className="label-input">
//               Quantity
//             </label>
//             <input
//               type="number"
//               className="input-form"
//               value={currentInventory.quantity}
//               onChange={(e) =>
//                 setCurrentInventory({ ...currentInventory, quantity: e.target.value })
//               }
//             />

//             <div className="container-btns">
//               <button
//                 type="button"
//                 className="btn btn-danger my-style-btn-form"
//                 onClick={() => popupEditt()}
//               >
//                 Cancel
//               </button>

//               <button type="submit" className="btn btn-primary my-style-btn-form">
//                 Update
//               </button>
//             </div>
//           </form>
//         </div>
//       )} */}
//         <svg width="100" height="100" viewBox="0 0 500 500">
//     <path
//       d="M379.957 159.593C386.507 160.265 391.274 166.119 390.602 172.67C389.931 179.22 384.077 183.986 377.526 183.315C370.975 182.644 366.209 176.789 366.88 170.239C367.551 163.688 373.406 158.922 379.957 159.593Z"
//       fill="#80D672"
//       className="breathe-vertical"
//     />
//   </svg>
//     </>
//   );
// }
